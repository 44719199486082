.MainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 7vh;
}

.MainLeftColumn {
  margin-top: 10%;
  justify-content: center;
}

.MainLeftColumn p {
  width: 80%;
  font-size: 16px;
}

.ProjectsContainer {
  margin-top: 115px !important;
  width: 100%;
}

.SkillsContainer {
  margin-top: 100px !important;
  margin-bottom: 70px !important;
  width: 100% !important;
}

.BlogContainer {
  width: 60% !important;
  justify-content: center;
  margin: auto; 
  font-size: 15px; 
  text-indent: 20px; 
  margin-bottom: 20px;
}

.ProjectBlogImage {
  width: 100%;
  display: flex;
  margin: auto;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.dividingRowProjects {
  margin-top: 20px !important;
}

@media (min-width: 768px) {
  .upperContainerHome {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media (max-width: 767px) {
  .MainLeftColumn {
    margin-top: 5%;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .MainLeftColumn p {
    font-size: 14px;
    margin: auto;
  }
  .FollowSocials {
    justify-content: center !important;
  }
  .ProjectsContainer {
    margin-top: 50px !important;
    width: '100%';
  }
  .projectCard {
    margin: 5px !important;
  }
  .MainRightColumn {
    margin-top: 50px !important;
    display: flex;
    justify-content: center;
  }
  .SkillsContainer {
    margin-top: 60px !important;
    margin-bottom: 70px !important;
    width: '50%' !important;
  }
  .BackLinkNavBar {
    margin-right: 20px !important; 
  }
  .BlogContainer {
    margin-top: 10px !important;
    width: 95% !important;
  }
  .dividingRowProjects {
    margin-top: 0px !important;
  }
}

.MainRightColumn {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

.SkillsList li {
    display: inline-table;
    width: auto;
    font-size: 18px;
    padding: 10px;
    margin: 5px;
    margin-bottom: 5px;
    text-align: center;
    background-color: #0C6C4A;
    color: white;
    cursor: pointer;
    border-radius: 8px;
}

.skillLogo {
  margin: 10px;
}

.projectImage {
  width: 90%;
  border: #dbdbdb 1px solid;
  margin: auto;
  justify-content: center;
  display: flex;
  margin-top: 5px;
  margin-bottom: 3px;
}

.projectCard {
  position: relative;
  border: 1px black;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  margin: 2px;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  width: 350px; 
  height: 400px; 
  justify-content: center;
  margin: auto;
}

.projectCard:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.45);
  /* transform: scale(1.005); */
}

.projectCardDesc {
  position: relative;
  width: 80%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  align-self: center;
  font-size: 12px;
}

.projectCardTitle {
  width: 90%;
  margin: auto;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
}

.projectCardButtons {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  justify-content: center;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  z-index: 2;
  pointer-events: auto;
}

.greenButton {
  background-color: #0E8A5F !important;
  color: white !important;
}

.greenButton:hover {
  background-color: #0c7951 !important;
}

.carouselContainer {
  padding-bottom: 35px;
}

#root > div > div:nth-child(2) > div > div > button.slick-arrow {
  color: black;
}

.slick-prev:before {
  color: grey !important;
  font-size: 32px !important;
}

.slick-next:before {
  color: grey !important;
  font-size: 32px !important;
}
